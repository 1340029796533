import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useState } from "react";
import { Link } from "react-router-dom";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Badge, MenuItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Logo from "../../image/okprintLogo.jpg";

const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const imagesPanier = useSelector((state) => state.gestionImage.allImages);
  const { Produits } = useSelector((state) => state.dataApp);
  const photoTypes = Object.keys(imagesPanier);
  const quantiteImage = photoTypes.map((p) => imagesPanier[p].length);
  const nombreTotalImages = quantiteImage.reduce(
    (total, nombre) => total + nombre,
    0
  );

  const navItems = Produits?.map((produit) => ({
    id: produit.id,
    name: produit.NomProduit,
    Link: `/produit/${produit.NomProduit}`,
  }));
  navItems.unshift({ id: 0, name: "Home", Link: "/" });
  navItems.push({ id: 3, name: "Evenements", Link: `/produit/polaroid` });
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item) => (
          <Link to={item.Link} style={{ textDecoration: "none" }}>
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                sx={{
                  textAlign: "center",
                }}
              >
                <ListItemText primary={item.name} sx={{ color: "black" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          background: "white",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          {/* Replace `Logo` with your actual logo path */}
          <Link to="/" style={{ textDecoration: "none" }}>
            {" "}
            {/* Apply styles to the link */}
            <Box
              component="img"
              src={Logo}
              alt="Logo"
              sx={{
                marginRight: "auto",
                width: { xs: "100%", sm: "80%" }, // Adjusted width for responsiveness
                maxWidth: "100%", // Ensures that the image doesn't exceed its container's width
                display: { xs: "none", md: "block" }, // Hides the logo in xs and displays in md and above
              }}
            />
          </Link>
          <Box sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}>
            {navItems.map((item) => (
              <Link
                to={item.Link}
                key={item.id}
                style={{ textDecoration: "none" }}
              >
                <Button sx={{ color: "black", margin: "0 10px" }}>
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, ml: 1 }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Box sx={{ justifyContent: "flex-end" }}>
            <MenuItem>
              <Link
                to="/panier"
                style={{ textDecoration: "none", color: "black" }}
              >
                <IconButton size="large" aria-label="show cart items">
                  <Badge badgeContent={nombreTotalImages} color="error">
                    <ShoppingBasketIcon />
                  </Badge>
                </IconButton>
              </Link>
              {localStorage.getItem("codeCommand") && (
                <Box sx={{ textAlign: "center", mt: 1 }}>
                  <Typography variant="h6" sx={{color:"black"}}>
                    Code: {localStorage.getItem("codeCommand")}
                  </Typography>
                </Box>
              )}
            </MenuItem>
          </Box>
          <Link to="/" style={{ textDecoration: "none" }}>
            {" "}
            {/* Apply styles to the link */}
            <Box
              component="img"
              src={Logo}
              alt="Logo"
              sx={{
                marginRight: "auto",
                width: { xs: "100%", sm: "80%" }, // Adjusted width for responsiveness
                maxWidth: "100%", // Ensures that the image doesn't exceed its container's width
                display: { xs: "block", md: "none" }, // Hides the logo in xs and displays in md and above
              }}
            />
          </Link>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
