import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const ImageCard = ({ src, onDelete , type }) => {
  console.log(type)
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Card
        style={{
          position: "relative",
          borderRadius: "20px",
          width: "80%",
          textAlign: "center",
        }}
      >
        <CardMedia
          component="img"
          style={{ width: "100%" }}
          image={src}
          alt="Image"
          sx={{
            width: type === "polaroid" ? "100%" : type === "instax" ? "90%" : "80%",
            height: "auto",
            maxWidth: type === "polaroid" ? "300px" : type === "instax" ? "400px" : "none",
            maxHeight: type === "polaroid" ? "300px" : "none", // Removed maxHeight for "instax"
            aspectRatio: type === "polaroid" ? "1 / 1" : type === "instax" ? "3/ 4" : "unset",
          }}
        />
        <IconButton
          onClick={onDelete}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            color: "#B31312",
          }}
        >
          <CancelIcon />
        </IconButton>
      </Card>
    </Box>
  );
};

export default ImageCard;
