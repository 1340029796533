import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Card,
  CardMedia,
  Typography,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  EditeImage,
  GetImageParCommand,
} from "../../store/slice/CommandDahbordSlice";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CropIcon from "@mui/icons-material/Crop";
import axios from "axios";
import ImprimantSucces from "../../composant/message/imprimanteSucces";
import { Api_Token } from "../../env";
import { BackLoadingSimple } from "../loading/backLoading";


export default function GalleryImageTraite({
  open,
  onClose,
  downloadImage,
  id,
}) {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const imprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant
  );
  const [AllImages, setAllImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);  
  const [openCropper, setOpenCropper] = useState(false);
  const [demonsion, setDemonsion] = useState({});
  const [src, setSrc] = useState();
  const [idImage, setIdImage] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [loading , setLoading] = useState(false)

  useEffect(() => {
    setAllImage(images);
  }, [images]);

  function editeImage(Crop) {
    setOpenCropper(false);
    const data = {
      ...Crop,
      id: idImage,
    };
    dispatch(EditeImage(data)).then((result) => {
      if (EditeImage.fulfilled.match(result)) {
        dispatch(GetImageParCommand(id));
      }
    });
  }

  const handleSelectImage = (id) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((imgId) => imgId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedImages.length === AllImages.length) {
      setSelectedImages([]); // Deselect all
    } else {
      setSelectedImages(AllImages.map((image) => image.id)); // Select all
    }
  };

  const sendToPrint = async () => {
    setLoading(true)
    const selectedImageUrls = AllImages.filter((image) =>
      selectedImages.includes(image.id)
    ).map((image) => ({
      url: image.ImageTraites[0].url,
      type: image.Produit?.NomProduit,
    }));

    const data = {
      imprimant: imprimant?.nomImprimant,
      image_urls: selectedImageUrls,
    };

    try {
      const response = await axios.post("http://127.0.0.1:5000/print_images", data);
      if (response.status === 200 || response.status === 207) {
        const results = response.data;
        const numberImageImprime = results.filter(
          (result) => result.message === "Image printed successfully"
        ).length;
        const allPrintedSuccessfully = results.every(
          (result) => result.message === "Image printed successfully"
        );

        if (allPrintedSuccessfully) {
          setOpenPopup(true);
        } else {
          alert("Some images failed to print. Please check logs.");
        }
        Api_Token.post(`command/editPriceCommand`, {
          idCommand:id,
          numberImageImprime:numberImageImprime
         })
      } else {
        console.error("Failed to send images. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false)
  };

  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogTitle>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Images Traitées
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          {AllImages?.map((image) => (
          <Grid item xs={3} key={image.id}>
            <Card>
              <Checkbox
                checked={selectedImages.includes(image.id)}
                onChange={() => handleSelectImage(image.id)}
                sx={{
                //  transform: 'scale(2)', 
                //  margin: '10px',          
                }}
              />
              <CardMedia
                onClick={() => handleSelectImage(image.id)}
                component="img"
                image={`${image.ImageTraites[0].url}?v=${new Date().getTime()}`}
              />
              <IconButton
                onClick={() => {
                  setIdImage(image.id);
                  setSrc(image.url);
                  setDemonsion(
                    imprimant?.demontions?.find(
                      (d) => image.idProduit === d.idProduit
                    )
                  );
                  setOpenCropper(true);
                }}
              >
                <CropIcon />
              </IconButton>
              <IconButton
                    onClick={() => {
                      downloadImage(image.ImageTraites[0].url, image.id);
                    }}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
            </Card>
          </Grid>
        ))}
          </Grid>
        </DialogContent>
        <DialogActions>
{          <Button variant="contained" onClick={handleSelectAll}>
          {selectedImages?.length === AllImages?.length
              ? "Désélectionner Tout"
              : "Tout Sélectionner"}
          </Button>}
          <Button variant="contained" onClick={sendToPrint}>
            Imprimer Images
          </Button>
          <Button onClick={onClose} variant="contained" color="error">Annulé</Button>
        </DialogActions>
      </Dialog>
      <ImageCropDialog
        open={openCropper}
        onClose={() => {
          setOpenCropper(false);
        }}
        src={src}
        demonsion={demonsion}
        editeImage={editeImage}
      />
      <ImprimantSucces
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      />
      <BackLoadingSimple open={loading}/>
    </>
  );
}


export function ImageCropDialog({ open, onClose, src, demonsion, editeImage }) {
  const [crop, setCrop] = useState({
    unit: "%",
    height: 100,
    aspect: demonsion?.typeCroperW / demonsion?.typeCroperH,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <div style={{ width: "50%", margin: "auto" }}>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            aspect={demonsion?.typeCroperW / demonsion?.typeCroperH}
          >
            <img
              src={src}
              alt="Crop me"
              style={{ maxWidth: "auto", height: "400px" }}
            />
          </ReactCrop>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={() => editeImage(crop)}>Recadrer</Button>
      </DialogActions>
    </Dialog>
  );
}  

