import axios from "axios";
import Cookies from "js-cookie";


const  api_base = "https://okprint.ma/okprintApp/";
//const api_base = "http://localhost:5000/AppOkPrint/";
//const api_base = "http://192.168.1.4:5000/AppOkPrint/";

const Api_Token = axios.create({
  baseURL: api_base,
  // headers: {
  //    'Content-Type': 'application/json',
  //  }
});

function updateAuthToken(token) {
  Api_Token.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const authToken = Cookies.get("token");
if (authToken !== undefined) {
  updateAuthToken(authToken);
}
export { api_base, updateAuthToken, Api_Token };
