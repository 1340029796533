import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress, {
} from "@mui/material/CircularProgress";
import { Typography, Box } from "@mui/material";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        style={{
          width: "80px", // Adjust to your desired width
          height: "80px", // Adjust to your desired height
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5" // Adjusted for a larger predefined style
          component="div"
          color="white"
          sx={{
            fontSize: "1.5rem", // Custom font size, adjust as needed
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function BackLoading({ open, Progress }) {
  return (
    <Backdrop
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
      open={open}
    >
      <CircularProgressWithLabel value={Progress} />
    </Backdrop>
  );
}

export function BackLoadingSimple({ open }) {
  return (
    <Backdrop
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute", 
        flexDirection: "column",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
      open={open}
    >
      <CircularProgress />
    </Backdrop>
  );
}

/* 

color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 



sx={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999, 
}}>*/
