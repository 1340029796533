import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import logo from "../../image/okprintLogo.jpg";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Api_Token } from "../../env";
import CustomKeyboard from "../../composant/clavie/keyboard";

export default function SearchCommand() {
  const [code, setCode] = useState(""); // Initialize as an empty string
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Added inputValue state for keyboard input
  const [currentLayout, setCurrentLayout] = useState("default");

  const navigate = useNavigate();

  const handleKeyPress = (button) => {
    switch (button) {
      case "{shift}":
        setCurrentLayout((prevLayout) =>
          prevLayout === "default" ? "shift" : "default"
        );
        break;
      case "{numbers}":
        setCurrentLayout("numbers");
        break;
      case "{default}":
        setCurrentLayout("default");
        break;
      case "{delete}":
        if (inputValue) {
          const updatedValue = inputValue.slice(0, -1); // Remove the last character
          setInputValue(updatedValue);
          setCode(updatedValue);
        }
        break;
      default:
        setInputValue((prevValue) => prevValue + button);
        break;
    }
  };

  const handleOpenKeyboard = () => {
    setKeyboardOpen(true);
    setInputValue(code); // Populate keyboard with the current code value
  };

  const handleCloseKeyboard = () => {
    setKeyboardOpen(false);
  };

  const onChange = (input) => {
    setInputValue(input); // Update input value from keyboard
    setCode(input); // Synchronize with the code input field
  };

  const search = () => {
    Api_Token.get(`command/traitebycode/${code}`)
      .then((response) => {
        navigate(`/admin/imageTraite/${response.data.idCommand}`);
      })
      .catch((error) => {
        console.error("Error fetching command:", error);
        alert("Command not found");
      });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: "20px",
      }}
    >
      <Grid
        container
        textAlign="center"
        spacing={2}
        //  direction="column"
        //  alignItems="center"
      >
        <Grid item xs={12}>
          <Box
            component="img"
            src={logo}
            alt="OK Print Logo"
            sx={{ maxWidth: "300px", mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                md: "26px",
                lg: "28px",
                xl: "30px",
              },
            }}
          >
            Welcome to OK Print
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Code de Command"
            label="Code de Command"
            name="code"
            sx={{ width: "500px", mt: 2 }}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onFocus={handleOpenKeyboard}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            onClick={search}
            sx={{ mt: 2 }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      {keyboardOpen && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            background: "#f4f4f4",
            zIndex: 1000,
            padding: "10px 0",
            boxShadow: "0px -2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <CustomKeyboard
            inputValue={inputValue}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            layoutName={currentLayout}
            closeKeyboard={handleCloseKeyboard}
          />
        </div>
      )}
    </Box>
  );
}
