import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import {  useState } from "react";
import { useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/InfoOutlined';
;

export default function AddParamsPopup({ open, onClose, idImprimant, send }) {
  const produits = useSelector((state) => state.dataApp.Produits);
  const [Produit, setproduit] = useState();
  const submitForm = (event) => {
    event.preventDefault();
    const data = {
      idImprimant: idImprimant,
      idProduit: Produit.id,
      image_margin_left: parseInt(event.target.image_margin_left.value),
      image_margin_Top: parseInt(event.target.image_margin_Top.value),
      image_height: parseInt(event.target.image_height.value),
      image_width: parseInt(event.target.image_width.value),
      paper_height: parseInt(event.target.paper_height.value),
      paper_width: parseInt(event.target.paper_width.value),
      croper_height: parseInt(event.target.croper_height.value),
      croper_width: parseInt(event.target.croper_width.value),
    };
    send(data);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">
        <h2>Ajouter Parametre</h2>
      </DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent>
          <Box>
            <Grid container spacing={2} textAlign="center" marginTop="1%">
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="produit"
                  options={produits}
                  getOptionLabel={(option) => option.NomProduit}
                  onChange={(event, value) => setproduit(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="produit"
                      required
                      name="produit"
               
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="margin left"
                  name="image_margin_left"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="la marge de image en left">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="margin Top"
                  name="image_margin_Top"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="la marge de image en top">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Height image"
                  name="image_height"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title=" image de hauteur">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Width image"
                  name="image_width"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="image en largeur">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Height paper "
                  name="paper_height"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="paper de hauteur">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label=" Width paper"
                  name="paper_width"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="image de largeur">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="cropper Height "
                  name="croper_height"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="hauteur de coupe">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="croper Width"
                  name="croper_width"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="largeur de coupe">
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error">
            annuler
          </Button>
          <Button variant="contained" type="submit">
            ajouter
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
