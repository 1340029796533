import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import { Button, IconButton, TextField } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Badge } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import {
  GetCommand,
  GetImageParCommand,
  TraiteCommand,
  inisialAlertMessageCommand,
} from "../../store/slice/CommandDahbordSlice";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { Api_Token } from "../../env";
import GalleryImageTraite from "../../composant/admin/galleryImageTraite";
import axios from "axios";

export default function CommandDashbord() {
  const { loading } = useSelector((state) => state.commands);

  return (
    <>
      <Indexdasbord Children={<TableauCommand />} />
      <BackLoadingSimple open={loading} />
    </>
  );
}

function TableauCommand() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { commands, alertMessage, etat } = useSelector(
    (state) => state.commands
  );
  const [nomFilter, setnomFilter] = React.useState("");
  const [telephoneFilter, setTelephoneFilter] = React.useState("");
  const [dateFilter, setdateFilter] = React.useState();
  const [PopupTraite, setPopupTraite] = React.useState(false);
  const [IdPopupTraite, setIdPopupTraite] = React.useState();
  const dispatch = useDispatch();

  const getCommadParData = useCallback(() => {
    const data = {
      page: page,
      rowsPerPage: rowsPerPage,
      nomFilter: nomFilter,
      telephoneFilter: telephoneFilter,
      dateFilter: dateFilter,
    };
    dispatch(GetCommand(data));
  }, [dispatch, page, rowsPerPage, nomFilter, telephoneFilter, dateFilter]);

  useEffect(() => {
    getCommadParData();
  }, [page, rowsPerPage, getCommadParData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 0, align: "center", minWidth: "60px", label: "Nom" },
    { id: 1, align: "center", minWidth: "60px", label: "Telephone" },
    { id: 2, align: "center", minWidth: "60px", label: "Etat traitement" },
    { id: 2, align: "center", minWidth: "60px", label: "prix" },
    { id: 3, align: "center", minWidth: "60px", label: "code" },
    { id: 4, align: "center", minWidth: "60px", label: "Date" },
    { id: 5, align: "center", minWidth: "60px", label: "Original" },
    { id: 6, align: "center", minWidth: "60px", label: "Traite" },
  ];

  function getDate(dt) {
    const date = new Date(dt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Note: Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${year}-${month}-${day} | ${hours}:${minutes}`;
  }
  function traite(id) {
    const data = {
      idCommand: id,
    };
    dispatch(TraiteCommand(data)).then((result) => {
      if (TraiteCommand.fulfilled.match(result)) {
        getCommadParData();
      }
    });
  }

  async function downloadtraiteZib(id, nom) {
    try {
      const res = await Api_Token.get(`/command/traiteZibCommand/${id}`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nom}_traite.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  async function downloadOriginalZib(id, nom) {
    try {
      const res = await Api_Token.get(`/command/zibCommand/${id}`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nom}_original.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  async function downloadImage(url, id) {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(response.data);
      link.href = objectURL;
      link.download = `image${id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  function getImagesPopup(id) {
    dispatch(GetImageParCommand(id)).then((result) => {
      if (GetImageParCommand.fulfilled.match(result)) {
        setPopupTraite(true);
      }
    });
  }

  return (
    <>
      <Paper sx={{ width: "90%", overflow: "hidden", marginLeft: "5%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell c align="center">
                  <TextField
                    placeholder="Nom"
                    type="text"
                    variant="standard"
                    value={nomFilter}
                    onChange={(e) => setnomFilter(e.target.value)}
                  />
                </TableCell>
                <TableCell colSpan={2} align="center">
                  <TextField
                    placeholder="telephone"
                    type="tel"
                    variant="standard"
                    value={telephoneFilter}
                    onChange={(e) => setTelephoneFilter(e.target.value)}
                  />
                </TableCell>
                <TableCell colSpan={2} align="center">
                  <TextField
                    type="date"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateFilter}
                    onChange={(e) => setdateFilter(e.target.value)}
                  />
                </TableCell>  
                <TableCell>
              
                </TableCell>
                <TableCell>
              
              </TableCell>
                <TableCell>
                  <Button variant="contained" onClick={getCommadParData}>
                    filter
                  </Button>
                </TableCell>

              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, top: 54 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell style={{ top: 54 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commands?.data?.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell align="center">{row.NomClient}</TableCell>
                    <TableCell align="center">{row.Telephone}</TableCell>
                    <TableCell align="center">{row.etat}</TableCell>
                    <TableCell align="center">{row.prix} dhs</TableCell>
                    <TableCell align="center">{row.code}</TableCell>
                    <TableCell align="center">
                      {getDate(row.createdAt)}
                    </TableCell>
                    <TableCell align="center">
                      <Badge badgeContent={row.ImageCommdeCount} color="error">
                        <CollectionsIcon />
                      </Badge>
                      <IconButton
                        onClick={() => {
                          downloadOriginalZib(row.id, row.NomClient);
                        }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </TableCell>
                    {(row.etat === "traité" || row.etat === "livré") && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            getImagesPopup(row.id);
                            setIdPopupTraite(row.id);
                          }}
                        >
                          <Badge
                            badgeContent={row.ImageCommdeCount}
                            color="error"
                          >
                            <CollectionsIcon />
                          </Badge>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            downloadtraiteZib(row.id, row.NomClient);
                          }}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {row.etat !== "traité" &&
                      row.etat !== "livré" &&
                      row.etat !== "Annulé" && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              traite(row.id);
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    <TableCell align="center">{row.nom}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={commands?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar
        open={alertMessage}
        autoHideDuration={3000}
        onClose={() => {
          dispatch(inisialAlertMessageCommand());
        }}
        anchorOrigin={{ vertical: "buttom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            dispatch(inisialAlertMessageCommand());
          }}
          severity={etat.error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {etat.message}
        </Alert>
      </Snackbar>
      <GalleryImageTraite
        open={PopupTraite}
        onClose={() => {
          setPopupTraite(false);
        }}
        downloadImage={downloadImage}
        id={IdPopupTraite}
      />
    </>
  );
}
